import React, { FC, createContext, useContext, useState, useCallback } from 'react';
import find from 'lodash/find';
import HolisticFrame from '../utils/HolisticFrame';
import { ProjectType } from '../API';

export type Label = {
  name: string;
  frames: HolisticFrame[];
};

export type Capture = {
  model: ProjectType;
  classname: string;
  frames: HolisticFrame[];
};

export type LabelsContextProps = {
  labels: Label[];
  captures: Capture[];
  setLabels: (labels: Label[]) => void;
  addLabel: (label: Label) => void;
  getLabel: (name: string) => Label | undefined;
};
export type LabelsContextProviderProps = {
  defaultLabels?: Label[];
  projectType: ProjectType;
};

export const LabelsContext = createContext<LabelsContextProps>({
  labels: [],
  captures: [],
  setLabels: () => {},
  addLabel: () => {},
  getLabel: () => undefined,
});

export const LabelsContextProvider: FC<LabelsContextProviderProps> = ({
  children,
  defaultLabels = [],
  projectType,
}) => {
  const [labels, setLabels] = useState<Label[]>(defaultLabels);

  const addLabel = useCallback(
    (label: Label) => {
      const { name } = label;

      const index = labels.findIndex((item) => item.name === name);
      if (index !== -1) {
        setLabels([...labels.slice(0, index), label, ...labels.slice(index + 1)]);
      } else {
        setLabels([...labels, label]);
      }
    },
    [labels]
  );

  const getLabel = useCallback((name: string) => find(labels, { name }), [labels]);

  const captures: Capture[] = labels.map(({ name, frames }) => ({
    model: projectType,
    classname: name,
    frames,
  }));

  return (
    <LabelsContext.Provider value={{ labels, setLabels, addLabel, getLabel, captures }}>
      {children}
    </LabelsContext.Provider>
  );
};

export default () => useContext(LabelsContext);
