import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { v4 as uuid } from 'uuid';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { getErrorMessage } from '../utils/messages';
import ProjectListItem from '../componets/project-list/ProjectListItem';
import ProjectListFilters from '../componets/project-list/ProjectListFilters';
import { useListProjectsQuery, useCreateProjectMutation, Project } from '../API';
import AddProjectDialog, {
  AddProjectDialogProps,
} from '../componets/project-list/AddProjectDialog';
import Loading from '../componets/common/Loading';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: 'fixed',
      bottom: theme.spacing(3),
      right: theme.spacing(3),
    },
    toggle: {
      marginLeft: theme.spacing(3),
      position: 'relative',
      top: theme.spacing(-1),
    },
  })
);

type ProjectListPageProps = {
  userEmail: string;
  userId: string;
};

const ProjectListPage = ({ userEmail, userId }: ProjectListPageProps) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const {
    data: listProjectData,
    loading: listProjectLoading,
    error: listProjectError,
  } = useListProjectsQuery();
  const [createProject] = useCreateProjectMutation();

  const [openAddProjectDialog, setOpenAddProjectDialog] = useState<boolean>(false);
  const [userProjects, setUserProjects] = useState<boolean>(false);
  const [projectTypeFilters, setProjectTypeFilters] = useState<string[]>([]);

  if (listProjectError) {
    const { message, variant }: any = getErrorMessage(listProjectError);
    enqueueSnackbar(message, { variant });
  }

  if (listProjectLoading) return <Loading />;

  let projects = listProjectData?.listProjects?.items || [];
  if (userProjects || projectTypeFilters.length > 0) {
    projects = projects.filter((project) => {
      if (userProjects && project.userId !== userId) return false;
      return !(
        projectTypeFilters.length > 0 && !projectTypeFilters.includes(project.projectType as string)
      );
    });
  }

  const gotToProject = (pk: string | undefined) => (pk ? history.push(`/project/${pk}`) : null);

  const handleAddProjectSubmit: AddProjectDialogProps['onSubmit'] = async (dataForm) => {
    try {
      const response = await createProject({
        variables: {
          input: {
            name: dataForm.name,
            labels: dataForm.labels
              .split(',')
              .map((label) => ({ label: label.trim(), id: uuid() })),
            projectType: dataForm.projectType,
            private: dataForm.private,
            version: 0,
            userEmail,
          },
        },
      });
      setOpenAddProjectDialog(false);
      const { pk } = response?.data?.createProject || {};
      enqueueSnackbar(`Project created: ${pk}`, {
        variant: 'success',
      });
      gotToProject(pk);
    } catch (err) {
      const { message, variant }: any = getErrorMessage(err);
      enqueueSnackbar(message, { variant });
    }
  };

  return (
    <>
      <Typography component="h1" variant="h3" gutterBottom>
        Projects
        <ButtonGroup className={classes.toggle}>
          <Button
            color={!userProjects ? 'primary' : 'default'}
            onClick={(): void => setUserProjects(false)}
          >
            All
          </Button>
          <Button
            color={userProjects ? 'primary' : 'default'}
            onClick={(): void => setUserProjects(true)}
          >
            My Projects
          </Button>
        </ButtonGroup>
        <ProjectListFilters filters={projectTypeFilters} setFilters={setProjectTypeFilters} />
      </Typography>
      {projects.length ? (
        <Grid container alignItems="stretch" spacing={3}>
          {projects.map((project) => (
            <ProjectListItem
              key={project.pk}
              project={project as Project}
              gotToProject={gotToProject}
            />
          ))}
        </Grid>
      ) : (
        <Typography component="p" variant="h5" gutterBottom>
          No projects found!
        </Typography>
      )}
      <AddProjectDialog
        open={openAddProjectDialog}
        onClose={() => setOpenAddProjectDialog(false)}
        onSubmit={handleAddProjectSubmit}
      />
      <Fab
        aria-label="add new project"
        className={classes.fab}
        color="primary"
        onClick={() => setOpenAddProjectDialog(true)}
      >
        <AddIcon />
      </Fab>
    </>
  );
};

export default ProjectListPage;
