import React from 'react';
import { saveAs } from 'file-saver';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Model as ModelType, useGetModelPosesLazyQuery } from '../../API';
import Model from './Model';

export type ModelListProps = {
  models: ModelType[];
  removeFromModels: (modelId: string) => void;
  refreshModel: (model: ModelType) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomSpacing: {
      '& > *': {
        margin: theme.spacing(2, 0),
      },
    },
  })
);

const ModelList = ({ models, removeFromModels, refreshModel }: ModelListProps): JSX.Element => {
  const classes = useStyles();

  const [getPoses] = useGetModelPosesLazyQuery({
    onCompleted: (data) => {
      const poses = data?.getModel?.poses || '';

      const blob = new Blob([poses], {
        type: 'text/json;charset=utf-8;',
      });
      saveAs(blob, 'poses.json');
    },
  });

  return (
    <section>
      <Typography component="h3" variant="h5" gutterBottom>
        Models
      </Typography>
      <div className={classes.bottomSpacing}>
        {models.map((model, index) => (
          <Model
            onDownload={getPoses}
            onDelete={removeFromModels}
            onUpdate={refreshModel}
            key={`${model.pk}-${model.sk}`}
            index={index}
            model={model}
          />
        ))}
      </div>
    </section>
  );
};

export default ModelList;
