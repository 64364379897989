import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import PublishIcon from '@material-ui/icons/Publish';
import IconButton from '@material-ui/core/IconButton';
import ReplayIcon from '@material-ui/icons/Replay';

export type BlazeposeUploadPros = {
  captureSnapshot: (video: HTMLVideoElement) => void;
  loading: boolean;
};

type CaptureState = 'LOADING' | 'WAITING' | 'IDLE' | 'RECORDING';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    bottomPannel: {
      minHeight: 50,
      height: '10%',
    },
    overlay: {
      position: 'absolute',
      zIndex: theme.zIndex.appBar,
      bottom: 0,
      left: 0,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(255,255,255, .8)',
    },
    video: {
      width: '100%',
    },
  })
);

const BlazeposeUpload = ({ captureSnapshot, loading }: BlazeposeUploadPros): JSX.Element => {
  const classes = useStyles();
  const playerRef = useRef<HTMLVideoElement>(null);
  const sourceRef = useRef<HTMLSourceElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isVideoPlaying, setisVideoPlaying] = useState<boolean>(true);
  const [captureState, setCaptureState] = React.useState<CaptureState>('LOADING');

  useEffect(() => {
    if (!sourceRef?.current?.hasAttribute('src')) {
      inputRef?.current?.click();
    }
  }, []);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;

    if (captureState === 'RECORDING') {
      const fps = 10;
      const period = 1000 / fps;

      interval = setInterval(() => {
        captureSnapshot(playerRef.current!);
      }, period);
    }
    return (): void => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [captureState, captureSnapshot]);

  const play = (reload?: boolean) => {
    if (reload) {
      playerRef.current?.load();
    } else {
      playerRef.current?.play();
    }
    setisVideoPlaying(true);
    setCaptureState('RECORDING');
  };

  useEffect(() => {
    if (loading && captureState === 'WAITING') {
      captureSnapshot(playerRef.current!);
    } else if (!loading && captureState === 'WAITING') {
      play();
    }
    // eslint-disable-next-line
  }, [loading, captureState]);

  const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setisVideoPlaying(false);
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      const url = URL.createObjectURL(file);
      sourceRef.current?.setAttribute('src', url);
      playerRef.current?.load();
    }
  };

  const finishedVideo = () => {
    setCaptureState('IDLE');
    setisVideoPlaying(false);
  };

  return (
    <div className={classes.root}>
      <input ref={inputRef} type="file" hidden accept="video/*" onChange={handleFileSelected} />
      <video
        className={classes.video}
        muted
        ref={playerRef}
        playsInline
        onEnded={finishedVideo}
        onLoadedData={() => setCaptureState('WAITING')}
      >
        <source ref={sourceRef} />
      </video>
      {!isVideoPlaying && captureState !== 'RECORDING' && (
        <div className={clsx(classes.overlay, classes.bottomPannel)}>
          <div>
            <IconButton onClick={() => play()} aria-label="take snapshot" color="primary">
              <ReplayIcon />
            </IconButton>
            <IconButton
              onClick={() => inputRef?.current?.click()}
              aria-label="record"
              color="primary"
            >
              <PublishIcon color="error" />
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default BlazeposeUpload;
