import React, { FC, useState } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { Auth } from 'aws-amplify';

const SignInDialog: FC = () => {
  const [open, setOpen] = useState<boolean>(true);

  const handleConfirm = () => {
    setOpen(false);
    Auth.federatedSignIn({ customProvider: 'SkySSO' });
  };

  return (
    <>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={open}
      >
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button variant="contained" onClick={handleConfirm} color="primary" autoFocus>
            Sign in with SkySSO
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default SignInDialog;
