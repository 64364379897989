import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { getErrorMessage } from '../../utils/messages';
import { useCloneProjectMutation } from '../../API';

export type DataForm = {
  cloneId: string;
  name: string;
  private: boolean;
};

export type CloneProjectDialogProps = {
  projectId: string;
  projectName: string;
  userEmail: string;
  privateProject: boolean;
  onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'inline-block',
    },
    checkbox: {
      display: 'block',
      marginTop: theme.spacing(3),
    },
  })
);

const CloneProjectDialog = ({
  projectId,
  projectName,
  userEmail,
  privateProject,
  onClose,
}: CloneProjectDialogProps) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [cloneProject] = useCloneProjectMutation();
  const history = useHistory();

  const close = () => {
    setOpen(false);
    onClose();
  };

  // eslint-disable-next-line consistent-return
  const redirectToClonedProjectAndReload = (pk: string | undefined) => {
    if (!pk) return null;
    history.push(`/project/${pk}`);
    history.go(0);
  };

  const submitCloneProject = async (dataForm: DataForm) => {
    setLoading(true);
    try {
      const response = await cloneProject({
        variables: {
          input: {
            name: dataForm.name,
            cloneId: projectId,
            userEmail,
            private: dataForm.private,
          },
        },
      });
      setLoading(false);
      close();
      const { pk } = response?.data?.cloneProject || {};
      enqueueSnackbar(`Project created: ${pk}`, {
        variant: 'success',
      });
      redirectToClonedProjectAndReload(pk);
    } catch (err) {
      const { message, variant }: any = getErrorMessage(err);
      enqueueSnackbar(message, { variant });
    }
  };

  const schema = yup.object().shape({
    name: yup.string().default(`${projectName}(1)`).required(),
    private: yup.boolean().default(privateProject).notRequired(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const getRegisterProps = (name: string, type: string) => {
    const { ...registerProps } = register(name);
    switch (type) {
      case 'checkbox':
        return {
          ...registerProps,
        };
      default:
        return {
          ...registerProps,
          error: !!errors[name],
          helperText: errors[name]?.message,
          variant: 'standard',
          fullWidth: true,
          margin: 'normal',
        };
    }
  };

  const onPrivateChange = () => {
    const privateCheck = document.getElementById('clone-project-private')! as HTMLInputElement;
    privateCheck.click();
  };

  return (
    <>
      <Button startIcon={<FileCopyIcon />} onClick={() => setOpen(true)} color="primary">
        Clone
      </Button>
      <form
        id="clone-project-form"
        onSubmit={handleSubmit(submitCloneProject)}
        noValidate
        className={classes.form}
      >
        <Dialog
          open={open}
          onClose={close}
          aria-labelledby="clone-dialog-title"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="clone-dialog-title">Clone project: {projectName}</DialogTitle>
          <DialogContent>
            <TextField
              {...(getRegisterProps('name', 'text') as TextFieldProps)}
              defaultValue={`${projectName}(1)`}
              label="Name"
              required
            />
            <FormControl className={classes.checkbox}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    defaultChecked={privateProject}
                    onChange={() => onPrivateChange()}
                  />
                }
                label="Private Project"
              />
              <input
                type="checkbox"
                id="clone-project-private"
                hidden
                defaultChecked={privateProject}
                {...getRegisterProps('private', 'checkbox')}
              />
              <FormHelperText>
                This means only you can access this project.
                <br /> This can be changed at a later date.
              </FormHelperText>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={close} color="primary">
              Close
            </Button>
            <Button
              disabled={!isValid || loading}
              variant="contained"
              type="submit"
              form="clone-project-form"
              color="primary"
              autoFocus
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </>
  );
};

export default CloneProjectDialog;
