import React, { useState, SyntheticEvent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import SaveIcon from '@material-ui/icons/Save';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Results as HolisticResults } from '@mediapipe/holistic';
import holistic from '../../utils/mediapipeHolistic';
import FrameList from './FrameList';
import HolisticFrame from '../../utils/HolisticFrame';
import BlazeposeUpload from './BlazeposeUpload';
import BlazeposeCam from './BlazeposeCam';
import { ProjectType } from '../../API';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      '& > *': {
        marginRight: theme.spacing(1),
      },
    },
    framesWrapper: {
      maxHeight: 300,
      overflow: 'auto',
    },
    processorWrapper: {
      position: 'relative',
    },
    loader: {
      position: 'absolute',
      zIndex: theme.zIndex.appBar + 1,
      bottom: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(255,255,255, .8)',
    },
    spin: {
      color: theme.palette.primary.main,
      fontSize: '50px',
      position: 'relative',
      animation: '$spin 1.2s linear infinite',
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  })
);

export type BlazeposeWrapperProps = {
  onBack: (evt: SyntheticEvent<HTMLButtonElement>) => void;
  onSave: (evt: SyntheticEvent<HTMLButtonElement>, frames: HolisticFrame[]) => void;
  BlazePoseProcessor: typeof BlazeposeUpload | typeof BlazeposeCam;
  title: string;
  projectType: ProjectType;
  emptyText?: string;
};

const BlazeposeWrapper = ({
  BlazePoseProcessor,
  onBack,
  onSave,
  title,
  emptyText,
  projectType,
}: BlazeposeWrapperProps): JSX.Element => {
  const classes = useStyles();
  const [frames, setFrames] = useState<HolisticFrame[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const handleSave = (evt: SyntheticEvent<HTMLButtonElement>) => {
    onSave(evt, frames);
    onBack(evt);
  };

  holistic.onResults((frame: HolisticResults) => {
    if (loading) {
      setLoading(false);
      return;
    }

    const isHolistic = projectType === ProjectType.Holistic;
    const holisticFrame = {
      POSE_LANDMARKS: projectType === ProjectType.Pose || isHolistic ? frame.poseLandmarks : null,
      FACE_LANDMARKS: projectType === ProjectType.Face || isHolistic ? frame.faceLandmarks : null,
      LEFT_HAND_LANDMARKS:
        projectType === ProjectType.Hands || isHolistic ? frame.leftHandLandmarks : null,
      RIGHT_HAND_LANDMARKS:
        projectType === ProjectType.Hands || isHolistic ? frame.rightHandLandmarks : null,
    };

    setFrames([holisticFrame, ...frames]);
  });

  const captureSnapshot = async (video: HTMLVideoElement) => {
    holistic.send({ image: video });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Typography component="h5" variant="h6" gutterBottom>
          {title}
        </Typography>
        <div className={classes.processorWrapper}>
          {loading && (
            <div className={classes.loader}>
              <RotateRightIcon className={classes.spin} />
            </div>
          )}
          <BlazePoseProcessor captureSnapshot={captureSnapshot} loading={loading} />
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography component="h5" variant="h6" gutterBottom>
          Frames
        </Typography>
        <div className={classes.framesWrapper}>
          <FrameList
            emptyText={emptyText}
            projectType={projectType}
            onRemove={setFrames}
            frames={frames}
          />
        </div>
      </Grid>
      <Grid item xs={12} className={classes.actions}>
        {!!frames.length && (
          <Button onClick={handleSave} startIcon={<SaveIcon />} variant="contained" color="primary">
            Save
          </Button>
        )}
        <Button onClick={onBack} startIcon={<KeyboardBackspaceIcon />} variant="contained">
          Back
        </Button>
        <Button
          disabled={!frames.length}
          variant="outlined"
          startIcon={<ClearAllIcon />}
          onClick={() => setFrames([])}
        >
          Clear All
        </Button>
      </Grid>
    </Grid>
  );
};

export default BlazeposeWrapper;
