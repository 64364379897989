import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import ProjectType from '../project/ProjectType';
import { ProjectType as ProjectTypeEnum, Project } from '../../API';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(1, 1, 2, 0),
      },
    },
    card: {
      height: '100%',
      position: 'relative',
    },
  })
);

type ProjectListItemProps = {
  project: Project;
  gotToProject: (pk: string | undefined) => void;
};

const ProjectListItem = ({ project, gotToProject }: ProjectListItemProps) => {
  const classes = useStyles();

  return (
    <Grid key={project.pk} item xs={12} md={4}>
      <Card className={classes.card}>
        <CardActionArea onClick={() => gotToProject(project.pk)}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {project.name}
              <ProjectType
                type={project.projectType ?? ProjectTypeEnum.Pose}
                privateProject={project.private!}
              />
            </Typography>
            {project.labels && (
              <div className={classes.chips}>
                {project.labels.map(({ label }) => (
                  <Chip key={label} label={label} color="primary" />
                ))}
              </div>
            )}
            <Typography variant="body2">Created By: {project.userEmail}</Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default ProjectListItem;
