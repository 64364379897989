import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import ListItemText from '@material-ui/core/ListItemText';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import LoopIcon from '@material-ui/icons/Loop';

export type ModelStatsProps = {
  epochs?: number | null;
  batchSize?: number | null;
  testLoss?: number | null;
  testAccuracy?: number | null;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    lists: {
      display: 'flex',
    },
    list: {
      marginRight: theme.spacing(4),
    },
  })
);

const ModelStats = ({
  epochs,
  batchSize,
  testLoss,
  testAccuracy,
}: ModelStatsProps): JSX.Element => {
  const classes = useStyles();

  const roundToNDecimals = (value: number, decimalPlaces: number = 1): number => {
    const decimalPower = 10 ** decimalPlaces;
    return Math.round(value * decimalPower) / decimalPower;
  };

  return (
    <div className={classes.lists}>
      <List dense className={classes.list}>
        <ListItem>
          <ListItemIcon>
            <LoopIcon />
          </ListItemIcon>
          <ListItemText primary={`Epochs: ${epochs ?? '-'}`} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <SettingsApplicationsIcon />
          </ListItemIcon>
          <ListItemText primary={`Batch Size: ${batchSize ?? '-'}`} />
        </ListItem>
      </List>
      <List dense className={classes.list}>
        <ListItem>
          <ListItemIcon>
            <TrackChangesIcon />
          </ListItemIcon>
          <ListItemText
            primary={`Accuracy: ${
              testAccuracy !== undefined && testAccuracy !== null
                ? roundToNDecimals(testAccuracy, 4)
                : '-'
            }`}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <TrendingDownIcon />
          </ListItemIcon>
          <ListItemText
            primary={`Loss: ${
              testLoss !== undefined && testLoss !== null ? roundToNDecimals(testLoss, 4) : '-'
            }`}
          />
        </ListItem>
      </List>
    </div>
  );
};

export default ModelStats;
