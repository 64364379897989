/* eslint-disable react/no-array-index-key */
import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Box from '@material-ui/core/Box';
import HolisticFrame from '../../utils/HolisticFrame';
import CanvasFrame from './CanvasFrame';
import { ProjectType } from '../../API';

export type FrameListProps = {
  frames: HolisticFrame[];
  emptyText?: string;
  projectType: ProjectType;
  onRemove: (frames: HolisticFrame[]) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(0, 1, 1, 0),
        width: 120,
        height: 120,
      },
    },
    item: {
      position: 'relative',
      '&:hover .MuiIconButton-root': {
        opacity: 1,
      },
    },
    canvas: {
      width: '100%',
      height: 'auto',
      borderColor: theme.palette.grey[300],
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: '4px',
    },
    remove: {
      position: 'absolute',
      opacity: 0,
      transition: 'opacity .4s',
      top: -3,
      right: -3,
    },
    frameCount: {
      marginBottom: theme.spacing(2),
    },
  })
);

const FrameList = ({ frames, projectType, emptyText, onRemove }: FrameListProps): JSX.Element => {
  const classes = useStyles();

  const handleRemove = (itemIndex: number) =>
    onRemove(frames.filter((_, index) => itemIndex !== index));

  const frameCount = frames.length;
  if (frames && !frameCount) {
    return (
      <Typography component="h5" variant="subtitle1" gutterBottom>
        {emptyText ?? 'No frames to display please record the video.'}
      </Typography>
    );
  }

  return (
    <>
      <Typography className={classes.frameCount}>{frameCount} frames</Typography>
      <Box display="flex" flexWrap="wrap" alignContent="flex-start" className={classes.root}>
        {frames.map((frame, index) => (
          <div key={index} className={classes.item}>
            <CanvasFrame
              className={classes.canvas}
              key={index}
              frame={frame}
              projectType={projectType}
            />
            <IconButton
              disabled={!onRemove}
              onClick={() => handleRemove(index)}
              className={classes.remove}
              aria-label="delete"
            >
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
      </Box>
    </>
  );
};
export default FrameList;
