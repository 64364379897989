import { Holistic } from '@mediapipe/holistic';

const holistic = new Holistic({
  locateFile: (file: string) =>
    `https://cdn.jsdelivr.net/npm/@mediapipe/holistic@${process.env
      .REACT_APP_MEDIAPIPE_HOLISTIC_VERSION!}/${file}`,
});

holistic.setOptions({
  modelComplexity: 1,
  smoothLandmarks: true,
  minDetectionConfidence: 0.5,
  minTrackingConfidence: 0.5,
});

export default holistic;
