import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup, { RadioGroupProps } from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { ProjectType } from '../../API';

export type DataForm = {
  name: string;
  labels: string;
  projectType: ProjectType;
  private: boolean;
};

export type AddProjectDialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: SubmitHandler<DataForm>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radioWrapper: {
      marginTop: theme.spacing(4),
    },
    checkbox: {
      display: 'block',
      marginTop: theme.spacing(3),
    },
  })
);

const AddProjectDialog = ({ open, onClose, onSubmit }: AddProjectDialogProps) => {
  const classes = useStyles();

  const schema = yup.object().shape({
    name: yup.string().required(),
    labels: yup.string().required(),
    projectType: yup.string().default('pose').required(),
    private: yup.boolean().default(false).notRequired(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const getRegisterProps = (name: string, type: string) => {
    const { ...registerProps } = register(name);
    switch (type) {
      case 'radio':
      case 'checkbox':
        return {
          ...registerProps,
        };
      default:
        return {
          ...registerProps,
          error: !!errors[name],
          helperText: errors[name]?.message,
          variant: 'standard',
          fullWidth: true,
          margin: 'normal',
        };
    }
  };

  const getErrors = (name: string) => ({
    error: !!errors[name],
  });

  const onPrivateChange = () => {
    const privateCheck = document.getElementById('add-project-private')! as HTMLInputElement;
    privateCheck.click();
  };

  return (
    <form id="add-project-form" onSubmit={handleSubmit(onSubmit)} noValidate>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">Add new project</DialogTitle>
        <DialogContent>
          <TextField
            {...(getRegisterProps('name', 'text') as TextFieldProps)}
            label="Name"
            required
          />
          <TextField
            {...(getRegisterProps('labels', 'text') as TextFieldProps)}
            label="Labels"
            helperText="Comma separated value e.g. pose, pose 2"
            required
          />
          <FormControl
            {...getErrors('projectType')}
            component="fieldset"
            className={classes.radioWrapper}
          >
            <FormLabel component="legend" required>
              Project Type
            </FormLabel>
            <RadioGroup
              {...(getRegisterProps('projectType', 'radio') as RadioGroupProps)}
              aria-label="Project Type"
              defaultValue="pose"
            >
              <FormControlLabel value="pose" control={<Radio />} label="Pose" />
              <FormControlLabel value="hands" control={<Radio />} label="Hands" />
              <FormControlLabel value="face" control={<Radio />} label="Face" />
              {/* <FormControlLabel value="holistic" control={<Radio />} label="Holistic" /> */}
            </RadioGroup>
          </FormControl>
          <FormControl {...getErrors('private')} className={classes.checkbox}>
            <FormControlLabel
              control={<Checkbox color="primary" onChange={() => onPrivateChange()} />}
              label="Private Project"
            />
            <input
              type="checkbox"
              id="add-project-private"
              hidden
              {...getRegisterProps('private', 'checkbox')}
            />
            <FormHelperText>
              This means only you can access this project.
              <br /> This can be changed at a later date.
            </FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
          <Button
            variant="contained"
            type="submit"
            form="add-project-form"
            color="primary"
            autoFocus
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default AddProjectDialog;
