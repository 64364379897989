import React, { FC, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Graph from './Graph';

interface ModelGraphsProps {
  lossByEpochs?: number[] | null | undefined;
  valLossByEpochs?: number[] | null | undefined;
  accuracyByEpochs?: number[] | null | undefined;
  valAccuracyByEpochs?: number[] | null | undefined;
  renderTrigger: (open: () => void) => JSX.Element;
}

const ModelGraphs: FC<ModelGraphsProps> = ({
  renderTrigger,
  lossByEpochs,
  valLossByEpochs,
  accuracyByEpochs,
  valAccuracyByEpochs,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const showDialog = (): void => {
    setOpen(true);
  };

  const accuracyByEpochsData = accuracyByEpochs?.map((accuracy, index) => ({
    xAxisValue: index,
    accuracy,
    valAccuracy: valAccuracyByEpochs ? valAccuracyByEpochs[index] : undefined,
  }));

  const accuracyByEpochsLines = [
    {
      title: 'Accuracy',
      dataKey: 'accuracy',
      color: '#8884d8',
    },
    {
      title: 'Val Accuracy',
      dataKey: 'valAccuracy',
      color: '#4d8888',
    },
  ];

  const lossByEpochsData = lossByEpochs?.map((epochLoss, index) => ({
    xAxisValue: index,
    loss: epochLoss,
    valLoss: valLossByEpochs ? valLossByEpochs[index] : undefined,
  }));

  const lossByEpochsLines = [
    {
      title: 'Loss',
      dataKey: 'loss',
      color: '#8884d8',
    },
    {
      title: 'Val Loss',
      dataKey: 'valLoss',
      color: '#4d8888',
    },
  ];

  return (
    <>
      {renderTrigger(showDialog)}
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="sm"
        aria-labelledby="confirmation-dialog-title"
        open={open}
        fullWidth
      >
        <DialogTitle id="confirmation-dialog-title" hidden>
          Graphs
        </DialogTitle>
        <DialogContent>
          <Graph
            title="Accuracy per epoch"
            yTitle="Accuracy"
            xTitle="Epochs"
            data={accuracyByEpochsData!}
            lines={accuracyByEpochsLines}
          />
          <Divider />
          <Graph
            title="Loss per epoch"
            yTitle="Loss"
            xTitle="Epochs"
            data={lossByEpochsData!}
            lines={lossByEpochsLines}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ModelGraphs;
