import React from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import TrainIcon from '@material-ui/icons/Train';
import useLabels, { Capture } from '../../hooks/useLabels';

export type DataForm = {
  epochs: number;
  batchSize: number;
};

export type TrainData = {
  epochs: number;
  batchSize: number;
};

export type TrainModelProps = {
  onSubmit: SubmitHandler<TrainData>;
  isLoading: boolean;
};
const TrainModel = ({ onSubmit, isLoading }: TrainModelProps) => {
  const { captures } = useLabels();

  const schema = yup.object().shape({
    epochs: yup.number().required(),
    batchSize: yup.number().required(),
  });

  const {
    register,
    handleSubmit: handleUseFormSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const getRegisterProps = (name: string) => {
    const { ref: inputRef, ...registerProps } = register(name);
    return {
      inputRef,
      ...registerProps,
      error: !!errors[name],
      helperText: errors[name]?.message,
      variant: 'standard',
      fullWidth: true,
      margin: 'normal',
      inputProps: {
        min: 0,
      },
      type: 'number',
    } as TextFieldProps;
  };

  const handleSubmit: SubmitHandler<DataForm> = (dataForm, evt) => {
    onSubmit({ ...dataForm }, evt);
  };

  const isAnyFrameRecorded = (captures: Capture[]) =>
    captures.map((c) => c.frames.length).reduce((sum: number, val: number) => sum + val) > 0;

  return (
    <section>
      <Typography component="h3" variant="h5" gutterBottom>
        Train Model
      </Typography>
      <form id="add-project-form" onSubmit={handleUseFormSubmit(handleSubmit)} noValidate>
        <TextField {...getRegisterProps('epochs')} defaultValue={50} label="Epochs" required />
        <TextField
          {...getRegisterProps('batchSize')}
          defaultValue={32}
          label="Batch Size"
          required
        />
        <Button
          disabled={!isValid || !captures.length || isLoading || !isAnyFrameRecorded(captures)}
          type="submit"
          startIcon={<TrainIcon />}
          variant="contained"
          color="primary"
        >
          Train
        </Button>
      </form>
    </section>
  );
};

export default TrainModel;
