import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache';

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type ApiKey = {
  __typename?: 'ApiKey';
  createdDate?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['String']>;
};

export type CloneProjectInput = {
  cloneId: Scalars['ID'];
  name: Scalars['String'];
  private: Scalars['Boolean'];
  userEmail: Scalars['String'];
};

export type Label = {
  __typename?: 'Label';
  id: Scalars['ID'];
  label: Scalars['String'];
};

export type LabelInput = {
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
};

export type LabelWithPoses = {
  __typename?: 'LabelWithPoses';
  id: Scalars['ID'];
  label: Scalars['String'];
  poses?: Maybe<Scalars['String']>;
};

export type Model = {
  __typename?: 'Model';
  createdAt?: Maybe<Scalars['String']>;
  pk: Scalars['ID'];
  poses?: Maybe<Scalars['String']>;
  s3Links?: Maybe<S3Links>;
  sk: Scalars['String'];
  stats?: Maybe<Stats>;
  status: TrainingStatus;
  trainingParameters?: Maybe<TrainingParameters>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type ModelInput = {
  pk: Scalars['ID'];
  s3Links?: Maybe<S3LinksInput>;
  sk: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  trainingParameters?: Maybe<TrainingParametersInput>;
};

export type ModelInputFilter = {
  name?: Maybe<Scalars['String']>;
};

export type ModelListResult = {
  __typename?: 'ModelListResult';
  items?: Maybe<Array<Maybe<Model>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  cloneProject?: Maybe<Project>;
  createApiKey?: Maybe<ApiKey>;
  createModel?: Maybe<Model>;
  createProject?: Maybe<Project>;
  createTraining?: Maybe<Model>;
  deleteApiKey?: Maybe<ApiKey>;
  deleteModel?: Maybe<Model>;
  deleteProject?: Maybe<Project>;
  updateModel?: Maybe<Model>;
  updateModelStatus?: Maybe<Model>;
  updateProject?: Maybe<Project>;
  uploadProjectPoses?: Maybe<Scalars['Boolean']>;
};

export type MutationCloneProjectArgs = {
  input?: Maybe<CloneProjectInput>;
};

export type MutationCreateApiKeyArgs = {
  userId: Scalars['ID'];
};

export type MutationCreateModelArgs = {
  input?: Maybe<ModelInput>;
};

export type MutationCreateProjectArgs = {
  input?: Maybe<ProjectInput>;
};

export type MutationCreateTrainingArgs = {
  input?: Maybe<TrainingInput>;
};

export type MutationDeleteApiKeyArgs = {
  userId: Scalars['ID'];
};

export type MutationDeleteModelArgs = {
  pk: Scalars['ID'];
  sk: Scalars['String'];
};

export type MutationDeleteProjectArgs = {
  pk: Scalars['ID'];
};

export type MutationUpdateModelArgs = {
  input?: Maybe<ModelInput>;
  pk: Scalars['ID'];
  sk: Scalars['String'];
};

export type MutationUpdateModelStatusArgs = {
  input?: Maybe<TrainingStatusUpdateInput>;
  pk: Scalars['ID'];
  sk: Scalars['String'];
};

export type MutationUpdateProjectArgs = {
  input?: Maybe<ProjectInput>;
  pk: Scalars['ID'];
};

export type MutationUploadProjectPosesArgs = {
  input: Scalars['String'];
  labelId: Scalars['String'];
  pk: Scalars['ID'];
};

export type Project = {
  __typename?: 'Project';
  createdAt: Scalars['String'];
  labels: Array<Label>;
  labelsWithPoses: Array<LabelWithPoses>;
  models?: Maybe<Array<Model>>;
  name: Scalars['String'];
  pk: Scalars['ID'];
  private?: Maybe<Scalars['Boolean']>;
  projectType?: Maybe<ProjectType>;
  updatedAt: Scalars['String'];
  userEmail?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
};

export type ProjectInput = {
  labels?: Maybe<Array<Maybe<LabelInput>>>;
  name?: Maybe<Scalars['String']>;
  private?: Maybe<Scalars['Boolean']>;
  projectType?: Maybe<ProjectType>;
  userEmail?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
};

export type ProjectInputFilter = {
  name?: Maybe<Scalars['String']>;
};

export type ProjectListResult = {
  __typename?: 'ProjectListResult';
  items: Array<Project>;
  nextToken?: Maybe<Scalars['String']>;
};

export enum ProjectType {
  Face = 'face',
  Hands = 'hands',
  Holistic = 'holistic',
  Pose = 'pose',
}

export type Query = {
  __typename?: 'Query';
  getApiKey?: Maybe<ApiKey>;
  getModel?: Maybe<Model>;
  getProject?: Maybe<Project>;
  getProjectsLatestModel?: Maybe<Model>;
  listModels: ModelListResult;
  listProjects: ProjectListResult;
};

export type QueryGetApiKeyArgs = {
  userId: Scalars['ID'];
};

export type QueryGetModelArgs = {
  pk: Scalars['ID'];
  sk: Scalars['String'];
};

export type QueryGetProjectArgs = {
  pk: Scalars['ID'];
};

export type QueryGetProjectsLatestModelArgs = {
  pk: Scalars['ID'];
};

export type QueryListModelsArgs = {
  filter?: Maybe<ModelInputFilter>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type QueryListProjectsArgs = {
  filter?: Maybe<ProjectInputFilter>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type S3Links = {
  __typename?: 'S3Links';
  labels?: Maybe<Scalars['String']>;
  tf?: Maybe<Scalars['String']>;
  tfjs?: Maybe<Scalars['String']>;
  tflite?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type S3LinksInput = {
  labels?: Maybe<Scalars['String']>;
  tf?: Maybe<Scalars['String']>;
  tfjs?: Maybe<Scalars['String']>;
  tflite?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type Stats = {
  __typename?: 'Stats';
  accuracyByEpochs?: Maybe<Array<Scalars['Float']>>;
  lossByEpochs?: Maybe<Array<Scalars['Float']>>;
  testAccuracy?: Maybe<Scalars['Float']>;
  testLoss?: Maybe<Scalars['Float']>;
  valAccuracyByEpochs?: Maybe<Array<Scalars['Float']>>;
  valLossByEpochs?: Maybe<Array<Scalars['Float']>>;
};

export type StatsInput = {
  accuracyByEpochs?: Maybe<Array<Scalars['Float']>>;
  lossByEpochs?: Maybe<Array<Scalars['Float']>>;
  testAccuracy?: Maybe<Scalars['Float']>;
  testLoss?: Maybe<Scalars['Float']>;
  valAccuracyByEpochs?: Maybe<Array<Scalars['Float']>>;
  valLossByEpochs?: Maybe<Array<Scalars['Float']>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  subscribeToModelUpdates?: Maybe<Model>;
};

export type SubscriptionSubscribeToModelUpdatesArgs = {
  pk: Scalars['String'];
  sk: Scalars['String'];
};

export type TrainingInput = {
  projectId?: Maybe<Scalars['String']>;
  trainingParameters?: Maybe<TrainingParametersInput>;
};

export type TrainingParameters = {
  __typename?: 'TrainingParameters';
  batchSize?: Maybe<Scalars['Int']>;
  epochs?: Maybe<Scalars['Int']>;
  learningRate?: Maybe<Scalars['Float']>;
};

export type TrainingParametersInput = {
  batchSize?: Maybe<Scalars['Int']>;
  epochs?: Maybe<Scalars['Int']>;
  learningRate?: Maybe<Scalars['Float']>;
};

export enum TrainingStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Ready = 'READY',
}

export type TrainingStatusUpdateInput = {
  s3Links?: Maybe<S3LinksInput>;
  stats?: Maybe<StatsInput>;
  status?: Maybe<Scalars['String']>;
};

export type CreateProjectMutationVariables = Exact<{
  input?: Maybe<ProjectInput>;
}>;

export type CreateProjectMutation = { __typename?: 'Mutation' } & {
  createProject?: Maybe<{ __typename?: 'Project' } & Pick<Project, 'pk'>>;
};

export type DeleteProjectMutationVariables = Exact<{
  pk: Scalars['ID'];
}>;

export type DeleteProjectMutation = { __typename?: 'Mutation' } & {
  deleteProject?: Maybe<{ __typename?: 'Project' } & Pick<Project, 'pk'>>;
};

export type UpdateProjectMutationVariables = Exact<{
  pk: Scalars['ID'];
  input?: Maybe<ProjectInput>;
}>;

export type UpdateProjectMutation = { __typename?: 'Mutation' } & {
  updateProject?: Maybe<{ __typename?: 'Project' } & Pick<Project, 'pk'>>;
};

export type CloneProjectMutationVariables = Exact<{
  input?: Maybe<CloneProjectInput>;
}>;

export type CloneProjectMutation = { __typename?: 'Mutation' } & {
  cloneProject?: Maybe<{ __typename?: 'Project' } & Pick<Project, 'pk'>>;
};

export type CreateTrainingMutationVariables = Exact<{
  input?: Maybe<TrainingInput>;
}>;

export type CreateTrainingMutation = { __typename?: 'Mutation' } & {
  createTraining?: Maybe<
    { __typename?: 'Model' } & Pick<Model, 'pk' | 'sk' | 'createdAt' | 'poses' | 'status'> & {
        trainingParameters?: Maybe<
          { __typename?: 'TrainingParameters' } & Pick<TrainingParameters, 'batchSize' | 'epochs'>
        >;
        stats?: Maybe<
          { __typename?: 'Stats' } & Pick<
            Stats,
            | 'testAccuracy'
            | 'testLoss'
            | 'lossByEpochs'
            | 'valLossByEpochs'
            | 'accuracyByEpochs'
            | 'valAccuracyByEpochs'
          >
        >;
        s3Links?: Maybe<
          { __typename?: 'S3Links' } & Pick<S3Links, 'tf' | 'tfjs' | 'tflite' | 'website'>
        >;
      }
  >;
};

export type UploadProjectPosesMutationVariables = Exact<{
  pk: Scalars['ID'];
  labelId: Scalars['String'];
  input: Scalars['String'];
}>;

export type UploadProjectPosesMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'uploadProjectPoses'
>;

export type DeleteModelMutationVariables = Exact<{
  pk: Scalars['ID'];
  sk: Scalars['String'];
}>;

export type DeleteModelMutation = { __typename?: 'Mutation' } & {
  deleteModel?: Maybe<{ __typename?: 'Model' } & Pick<Model, 'pk' | 'sk'>>;
};

export type CreateApiKeyMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type CreateApiKeyMutation = { __typename?: 'Mutation' } & {
  createApiKey?: Maybe<
    { __typename?: 'ApiKey' } & Pick<ApiKey, 'value' | 'enabled' | 'createdDate'>
  >;
};

export type DeleteApiKeyMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type DeleteApiKeyMutation = { __typename?: 'Mutation' } & {
  deleteApiKey?: Maybe<
    { __typename?: 'ApiKey' } & Pick<ApiKey, 'value' | 'enabled' | 'createdDate'>
  >;
};

export type ListProjectsQueryVariables = Exact<{ [key: string]: never }>;

export type ListProjectsQuery = { __typename?: 'Query' } & {
  listProjects: { __typename?: 'ProjectListResult' } & Pick<ProjectListResult, 'nextToken'> & {
      items: Array<
        { __typename?: 'Project' } & Pick<
          Project,
          'pk' | 'name' | 'userId' | 'userEmail' | 'projectType' | 'private'
        > & { labels: Array<{ __typename?: 'Label' } & Pick<Label, 'id' | 'label'>> }
      >;
    };
};

export type GetProjectQueryVariables = Exact<{
  pk: Scalars['ID'];
}>;

export type GetProjectQuery = { __typename?: 'Query' } & {
  getProject?: Maybe<
    { __typename?: 'Project' } & Pick<
      Project,
      'pk' | 'name' | 'projectType' | 'private' | 'version' | 'userEmail'
    > & {
        labelsWithPoses: Array<
          { __typename?: 'LabelWithPoses' } & Pick<LabelWithPoses, 'id' | 'label' | 'poses'>
        >;
        models?: Maybe<
          Array<
            { __typename?: 'Model' } & Pick<Model, 'pk' | 'sk' | 'createdAt' | 'status'> & {
                stats?: Maybe<{ __typename?: 'Stats' } & Pick<Stats, 'testAccuracy' | 'testLoss'>>;
                trainingParameters?: Maybe<
                  { __typename?: 'TrainingParameters' } & Pick<
                    TrainingParameters,
                    'batchSize' | 'epochs'
                  >
                >;
                s3Links?: Maybe<
                  { __typename?: 'S3Links' } & Pick<
                    S3Links,
                    'tf' | 'tfjs' | 'tflite' | 'website' | 'labels'
                  >
                >;
              }
          >
        >;
      }
  >;
};

export type GetModelQueryVariables = Exact<{
  pk: Scalars['ID'];
  sk: Scalars['String'];
}>;

export type GetModelQuery = { __typename?: 'Query' } & {
  getModel?: Maybe<
    { __typename?: 'Model' } & Pick<Model, 'pk' | 'sk' | 'createdAt' | 'status'> & {
        stats?: Maybe<
          { __typename?: 'Stats' } & Pick<
            Stats,
            | 'testAccuracy'
            | 'testLoss'
            | 'lossByEpochs'
            | 'valLossByEpochs'
            | 'accuracyByEpochs'
            | 'valAccuracyByEpochs'
          >
        >;
        trainingParameters?: Maybe<
          { __typename?: 'TrainingParameters' } & Pick<TrainingParameters, 'batchSize' | 'epochs'>
        >;
        s3Links?: Maybe<
          { __typename?: 'S3Links' } & Pick<
            S3Links,
            'tf' | 'tfjs' | 'tflite' | 'website' | 'labels'
          >
        >;
      }
  >;
};

export type GetModelPosesQueryVariables = Exact<{
  pk: Scalars['ID'];
  sk: Scalars['String'];
}>;

export type GetModelPosesQuery = { __typename?: 'Query' } & {
  getModel?: Maybe<{ __typename?: 'Model' } & Pick<Model, 'poses'>>;
};

export type GetApiKeyQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type GetApiKeyQuery = { __typename?: 'Query' } & {
  getApiKey?: Maybe<{ __typename?: 'ApiKey' } & Pick<ApiKey, 'value' | 'enabled' | 'createdDate'>>;
};

export type OnModelChangeSubscriptionVariables = Exact<{
  pk: Scalars['String'];
  sk: Scalars['String'];
}>;

export type OnModelChangeSubscription = { __typename?: 'Subscription' } & {
  subscribeToModelUpdates?: Maybe<
    { __typename?: 'Model' } & Pick<Model, 'status'> & {
        stats?: Maybe<{ __typename?: 'Stats' } & Pick<Stats, 'testAccuracy' | 'testLoss'>>;
        trainingParameters?: Maybe<
          { __typename?: 'TrainingParameters' } & Pick<TrainingParameters, 'batchSize' | 'epochs'>
        >;
        s3Links?: Maybe<
          { __typename?: 'S3Links' } & Pick<
            S3Links,
            'tf' | 'tfjs' | 'tflite' | 'website' | 'labels'
          >
        >;
      }
  >;
};

export const CreateProjectDocument = gql`
  mutation createProject($input: ProjectInput) {
    createProject(input: $input) {
      pk
    }
  }
`;
export type CreateProjectMutationFn = Apollo.MutationFunction<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(
    CreateProjectDocument,
    options
  );
}
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;
export const DeleteProjectDocument = gql`
  mutation deleteProject($pk: ID!) {
    deleteProject(pk: $pk) {
      pk
    }
  }
`;
export type DeleteProjectMutationFn = Apollo.MutationFunction<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      pk: // value for 'pk'
 *   },
 * });
 */
export function useDeleteProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteProjectMutation, DeleteProjectMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(
    DeleteProjectDocument,
    options
  );
}
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = Apollo.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>;
export const UpdateProjectDocument = gql`
  mutation updateProject($pk: ID!, $input: ProjectInput) {
    updateProject(pk: $pk, input: $input) {
      pk
    }
  }
`;
export type UpdateProjectMutationFn = Apollo.MutationFunction<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      pk: // value for 'pk'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(
    UpdateProjectDocument,
    options
  );
}
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>;
export const CloneProjectDocument = gql`
  mutation cloneProject($input: CloneProjectInput) {
    cloneProject(input: $input) {
      pk
    }
  }
`;
export type CloneProjectMutationFn = Apollo.MutationFunction<
  CloneProjectMutation,
  CloneProjectMutationVariables
>;

/**
 * __useCloneProjectMutation__
 *
 * To run a mutation, you first call `useCloneProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneProjectMutation, { data, loading, error }] = useCloneProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloneProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<CloneProjectMutation, CloneProjectMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CloneProjectMutation, CloneProjectMutationVariables>(
    CloneProjectDocument,
    options
  );
}
export type CloneProjectMutationHookResult = ReturnType<typeof useCloneProjectMutation>;
export type CloneProjectMutationResult = Apollo.MutationResult<CloneProjectMutation>;
export type CloneProjectMutationOptions = Apollo.BaseMutationOptions<
  CloneProjectMutation,
  CloneProjectMutationVariables
>;
export const CreateTrainingDocument = gql`
  mutation createTraining($input: TrainingInput) {
    createTraining(input: $input) {
      pk
      sk
      createdAt
      poses
      status
      trainingParameters {
        batchSize
        epochs
      }
      stats {
        testAccuracy
        testLoss
        lossByEpochs
        valLossByEpochs
        accuracyByEpochs
        valAccuracyByEpochs
      }
      s3Links {
        tf
        tfjs
        tflite
        website
      }
    }
  }
`;
export type CreateTrainingMutationFn = Apollo.MutationFunction<
  CreateTrainingMutation,
  CreateTrainingMutationVariables
>;

/**
 * __useCreateTrainingMutation__
 *
 * To run a mutation, you first call `useCreateTrainingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTrainingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrainingMutation, { data, loading, error }] = useCreateTrainingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTrainingMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTrainingMutation, CreateTrainingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTrainingMutation, CreateTrainingMutationVariables>(
    CreateTrainingDocument,
    options
  );
}
export type CreateTrainingMutationHookResult = ReturnType<typeof useCreateTrainingMutation>;
export type CreateTrainingMutationResult = Apollo.MutationResult<CreateTrainingMutation>;
export type CreateTrainingMutationOptions = Apollo.BaseMutationOptions<
  CreateTrainingMutation,
  CreateTrainingMutationVariables
>;
export const UploadProjectPosesDocument = gql`
  mutation uploadProjectPoses($pk: ID!, $labelId: String!, $input: String!) {
    uploadProjectPoses(pk: $pk, labelId: $labelId, input: $input)
  }
`;
export type UploadProjectPosesMutationFn = Apollo.MutationFunction<
  UploadProjectPosesMutation,
  UploadProjectPosesMutationVariables
>;

/**
 * __useUploadProjectPosesMutation__
 *
 * To run a mutation, you first call `useUploadProjectPosesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProjectPosesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProjectPosesMutation, { data, loading, error }] = useUploadProjectPosesMutation({
 *   variables: {
 *      pk: // value for 'pk'
 *      labelId: // value for 'labelId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadProjectPosesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadProjectPosesMutation,
    UploadProjectPosesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadProjectPosesMutation, UploadProjectPosesMutationVariables>(
    UploadProjectPosesDocument,
    options
  );
}
export type UploadProjectPosesMutationHookResult = ReturnType<typeof useUploadProjectPosesMutation>;
export type UploadProjectPosesMutationResult = Apollo.MutationResult<UploadProjectPosesMutation>;
export type UploadProjectPosesMutationOptions = Apollo.BaseMutationOptions<
  UploadProjectPosesMutation,
  UploadProjectPosesMutationVariables
>;
export const DeleteModelDocument = gql`
  mutation deleteModel($pk: ID!, $sk: String!) {
    deleteModel(pk: $pk, sk: $sk) {
      pk
      sk
    }
  }
`;
export type DeleteModelMutationFn = Apollo.MutationFunction<
  DeleteModelMutation,
  DeleteModelMutationVariables
>;

/**
 * __useDeleteModelMutation__
 *
 * To run a mutation, you first call `useDeleteModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteModelMutation, { data, loading, error }] = useDeleteModelMutation({
 *   variables: {
 *      pk: // value for 'pk'
 *      sk: // value for 'sk'
 *   },
 * });
 */
export function useDeleteModelMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteModelMutation, DeleteModelMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteModelMutation, DeleteModelMutationVariables>(
    DeleteModelDocument,
    options
  );
}
export type DeleteModelMutationHookResult = ReturnType<typeof useDeleteModelMutation>;
export type DeleteModelMutationResult = Apollo.MutationResult<DeleteModelMutation>;
export type DeleteModelMutationOptions = Apollo.BaseMutationOptions<
  DeleteModelMutation,
  DeleteModelMutationVariables
>;
export const CreateApiKeyDocument = gql`
  mutation createApiKey($userId: ID!) {
    createApiKey(userId: $userId) {
      value
      enabled
      createdDate
    }
  }
`;
export type CreateApiKeyMutationFn = Apollo.MutationFunction<
  CreateApiKeyMutation,
  CreateApiKeyMutationVariables
>;

/**
 * __useCreateApiKeyMutation__
 *
 * To run a mutation, you first call `useCreateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApiKeyMutation, { data, loading, error }] = useCreateApiKeyMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateApiKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateApiKeyMutation, CreateApiKeyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateApiKeyMutation, CreateApiKeyMutationVariables>(
    CreateApiKeyDocument,
    options
  );
}
export type CreateApiKeyMutationHookResult = ReturnType<typeof useCreateApiKeyMutation>;
export type CreateApiKeyMutationResult = Apollo.MutationResult<CreateApiKeyMutation>;
export type CreateApiKeyMutationOptions = Apollo.BaseMutationOptions<
  CreateApiKeyMutation,
  CreateApiKeyMutationVariables
>;
export const DeleteApiKeyDocument = gql`
  mutation deleteApiKey($userId: ID!) {
    deleteApiKey(userId: $userId) {
      value
      enabled
      createdDate
    }
  }
`;
export type DeleteApiKeyMutationFn = Apollo.MutationFunction<
  DeleteApiKeyMutation,
  DeleteApiKeyMutationVariables
>;

/**
 * __useDeleteApiKeyMutation__
 *
 * To run a mutation, you first call `useDeleteApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApiKeyMutation, { data, loading, error }] = useDeleteApiKeyMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteApiKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>(
    DeleteApiKeyDocument,
    options
  );
}
export type DeleteApiKeyMutationHookResult = ReturnType<typeof useDeleteApiKeyMutation>;
export type DeleteApiKeyMutationResult = Apollo.MutationResult<DeleteApiKeyMutation>;
export type DeleteApiKeyMutationOptions = Apollo.BaseMutationOptions<
  DeleteApiKeyMutation,
  DeleteApiKeyMutationVariables
>;
export const ListProjectsDocument = gql`
  query ListProjects {
    listProjects {
      items {
        pk
        name
        userId
        userEmail
        projectType
        private
        labels {
          id
          label
        }
      }
      nextToken
    }
  }
`;

/**
 * __useListProjectsQuery__
 *
 * To run a query within a React component, call `useListProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListProjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListProjectsQuery, ListProjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListProjectsQuery, ListProjectsQueryVariables>(
    ListProjectsDocument,
    options
  );
}
export function useListProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListProjectsQuery, ListProjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListProjectsQuery, ListProjectsQueryVariables>(
    ListProjectsDocument,
    options
  );
}
export type ListProjectsQueryHookResult = ReturnType<typeof useListProjectsQuery>;
export type ListProjectsLazyQueryHookResult = ReturnType<typeof useListProjectsLazyQuery>;
export type ListProjectsQueryResult = Apollo.QueryResult<
  ListProjectsQuery,
  ListProjectsQueryVariables
>;
export const GetProjectDocument = gql`
  query getProject($pk: ID!) {
    getProject(pk: $pk) {
      pk
      name
      projectType
      private
      version
      userEmail
      labelsWithPoses {
        id
        label
        poses
      }
      models {
        pk
        sk
        createdAt
        status
        stats {
          testAccuracy
          testLoss
        }
        trainingParameters {
          batchSize
          epochs
        }
        s3Links {
          tf
          tfjs
          tflite
          website
          labels
        }
      }
    }
  }
`;

/**
 * __useGetProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectQuery({
 *   variables: {
 *      pk: // value for 'pk'
 *   },
 * });
 */
export function useGetProjectQuery(
  baseOptions: Apollo.QueryHookOptions<GetProjectQuery, GetProjectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, options);
}
export function useGetProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProjectQuery, GetProjectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProjectQuery, GetProjectQueryVariables>(
    GetProjectDocument,
    options
  );
}
export type GetProjectQueryHookResult = ReturnType<typeof useGetProjectQuery>;
export type GetProjectLazyQueryHookResult = ReturnType<typeof useGetProjectLazyQuery>;
export type GetProjectQueryResult = Apollo.QueryResult<GetProjectQuery, GetProjectQueryVariables>;
export const GetModelDocument = gql`
  query getModel($pk: ID!, $sk: String!) {
    getModel(pk: $pk, sk: $sk) {
      pk
      sk
      createdAt
      status
      stats {
        testAccuracy
        testLoss
        lossByEpochs
        valLossByEpochs
        accuracyByEpochs
        valAccuracyByEpochs
      }
      trainingParameters {
        batchSize
        epochs
      }
      s3Links {
        tf
        tfjs
        tflite
        website
        labels
      }
    }
  }
`;

/**
 * __useGetModelQuery__
 *
 * To run a query within a React component, call `useGetModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelQuery({
 *   variables: {
 *      pk: // value for 'pk'
 *      sk: // value for 'sk'
 *   },
 * });
 */
export function useGetModelQuery(
  baseOptions: Apollo.QueryHookOptions<GetModelQuery, GetModelQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetModelQuery, GetModelQueryVariables>(GetModelDocument, options);
}
export function useGetModelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetModelQuery, GetModelQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetModelQuery, GetModelQueryVariables>(GetModelDocument, options);
}
export type GetModelQueryHookResult = ReturnType<typeof useGetModelQuery>;
export type GetModelLazyQueryHookResult = ReturnType<typeof useGetModelLazyQuery>;
export type GetModelQueryResult = Apollo.QueryResult<GetModelQuery, GetModelQueryVariables>;
export const GetModelPosesDocument = gql`
  query getModelPoses($pk: ID!, $sk: String!) {
    getModel(pk: $pk, sk: $sk) {
      poses
    }
  }
`;

/**
 * __useGetModelPosesQuery__
 *
 * To run a query within a React component, call `useGetModelPosesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelPosesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelPosesQuery({
 *   variables: {
 *      pk: // value for 'pk'
 *      sk: // value for 'sk'
 *   },
 * });
 */
export function useGetModelPosesQuery(
  baseOptions: Apollo.QueryHookOptions<GetModelPosesQuery, GetModelPosesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetModelPosesQuery, GetModelPosesQueryVariables>(
    GetModelPosesDocument,
    options
  );
}
export function useGetModelPosesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetModelPosesQuery, GetModelPosesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetModelPosesQuery, GetModelPosesQueryVariables>(
    GetModelPosesDocument,
    options
  );
}
export type GetModelPosesQueryHookResult = ReturnType<typeof useGetModelPosesQuery>;
export type GetModelPosesLazyQueryHookResult = ReturnType<typeof useGetModelPosesLazyQuery>;
export type GetModelPosesQueryResult = Apollo.QueryResult<
  GetModelPosesQuery,
  GetModelPosesQueryVariables
>;
export const GetApiKeyDocument = gql`
  query getApiKey($userId: ID!) {
    getApiKey(userId: $userId) {
      value
      enabled
      createdDate
    }
  }
`;

/**
 * __useGetApiKeyQuery__
 *
 * To run a query within a React component, call `useGetApiKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApiKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApiKeyQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetApiKeyQuery(
  baseOptions: Apollo.QueryHookOptions<GetApiKeyQuery, GetApiKeyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetApiKeyQuery, GetApiKeyQueryVariables>(GetApiKeyDocument, options);
}
export function useGetApiKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetApiKeyQuery, GetApiKeyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetApiKeyQuery, GetApiKeyQueryVariables>(GetApiKeyDocument, options);
}
export type GetApiKeyQueryHookResult = ReturnType<typeof useGetApiKeyQuery>;
export type GetApiKeyLazyQueryHookResult = ReturnType<typeof useGetApiKeyLazyQuery>;
export type GetApiKeyQueryResult = Apollo.QueryResult<GetApiKeyQuery, GetApiKeyQueryVariables>;
export const OnModelChangeDocument = gql`
  subscription onModelChange($pk: String!, $sk: String!) {
    subscribeToModelUpdates(pk: $pk, sk: $sk) {
      status
      stats {
        testAccuracy
        testLoss
      }
      trainingParameters {
        batchSize
        epochs
      }
      s3Links {
        tf
        tfjs
        tflite
        website
        labels
      }
    }
  }
`;

/**
 * __useOnModelChangeSubscription__
 *
 * To run a query within a React component, call `useOnModelChangeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnModelChangeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnModelChangeSubscription({
 *   variables: {
 *      pk: // value for 'pk'
 *      sk: // value for 'sk'
 *   },
 * });
 */
export function useOnModelChangeSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnModelChangeSubscription,
    OnModelChangeSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<OnModelChangeSubscription, OnModelChangeSubscriptionVariables>(
    OnModelChangeDocument,
    options
  );
}
export type OnModelChangeSubscriptionHookResult = ReturnType<typeof useOnModelChangeSubscription>;
export type OnModelChangeSubscriptionResult = Apollo.SubscriptionResult<OnModelChangeSubscription>;
export type ApiKeyKeySpecifier = ('createdDate' | 'enabled' | 'value' | ApiKeyKeySpecifier)[];
export type ApiKeyFieldPolicy = {
  createdDate?: FieldPolicy<any> | FieldReadFunction<any>;
  enabled?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LabelKeySpecifier = ('id' | 'label' | LabelKeySpecifier)[];
export type LabelFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  label?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LabelWithPosesKeySpecifier = ('id' | 'label' | 'poses' | LabelWithPosesKeySpecifier)[];
export type LabelWithPosesFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  label?: FieldPolicy<any> | FieldReadFunction<any>;
  poses?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ModelKeySpecifier = (
  | 'createdAt'
  | 'pk'
  | 'poses'
  | 's3Links'
  | 'sk'
  | 'stats'
  | 'status'
  | 'trainingParameters'
  | 'updatedAt'
  | ModelKeySpecifier
)[];
export type ModelFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  pk?: FieldPolicy<any> | FieldReadFunction<any>;
  poses?: FieldPolicy<any> | FieldReadFunction<any>;
  s3Links?: FieldPolicy<any> | FieldReadFunction<any>;
  sk?: FieldPolicy<any> | FieldReadFunction<any>;
  stats?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  trainingParameters?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ModelListResultKeySpecifier = ('items' | 'nextToken' | ModelListResultKeySpecifier)[];
export type ModelListResultFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  nextToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MutationKeySpecifier = (
  | 'cloneProject'
  | 'createApiKey'
  | 'createModel'
  | 'createProject'
  | 'createTraining'
  | 'deleteApiKey'
  | 'deleteModel'
  | 'deleteProject'
  | 'updateModel'
  | 'updateModelStatus'
  | 'updateProject'
  | 'uploadProjectPoses'
  | MutationKeySpecifier
)[];
export type MutationFieldPolicy = {
  cloneProject?: FieldPolicy<any> | FieldReadFunction<any>;
  createApiKey?: FieldPolicy<any> | FieldReadFunction<any>;
  createModel?: FieldPolicy<any> | FieldReadFunction<any>;
  createProject?: FieldPolicy<any> | FieldReadFunction<any>;
  createTraining?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteApiKey?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteModel?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteProject?: FieldPolicy<any> | FieldReadFunction<any>;
  updateModel?: FieldPolicy<any> | FieldReadFunction<any>;
  updateModelStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  updateProject?: FieldPolicy<any> | FieldReadFunction<any>;
  uploadProjectPoses?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProjectKeySpecifier = (
  | 'createdAt'
  | 'labels'
  | 'labelsWithPoses'
  | 'models'
  | 'name'
  | 'pk'
  | 'private'
  | 'projectType'
  | 'updatedAt'
  | 'userEmail'
  | 'userId'
  | 'version'
  | ProjectKeySpecifier
)[];
export type ProjectFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  labels?: FieldPolicy<any> | FieldReadFunction<any>;
  labelsWithPoses?: FieldPolicy<any> | FieldReadFunction<any>;
  models?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  pk?: FieldPolicy<any> | FieldReadFunction<any>;
  private?: FieldPolicy<any> | FieldReadFunction<any>;
  projectType?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userEmail?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  version?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProjectListResultKeySpecifier = (
  | 'items'
  | 'nextToken'
  | ProjectListResultKeySpecifier
)[];
export type ProjectListResultFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  nextToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type QueryKeySpecifier = (
  | 'getApiKey'
  | 'getModel'
  | 'getProject'
  | 'getProjectsLatestModel'
  | 'listModels'
  | 'listProjects'
  | QueryKeySpecifier
)[];
export type QueryFieldPolicy = {
  getApiKey?: FieldPolicy<any> | FieldReadFunction<any>;
  getModel?: FieldPolicy<any> | FieldReadFunction<any>;
  getProject?: FieldPolicy<any> | FieldReadFunction<any>;
  getProjectsLatestModel?: FieldPolicy<any> | FieldReadFunction<any>;
  listModels?: FieldPolicy<any> | FieldReadFunction<any>;
  listProjects?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type S3LinksKeySpecifier = (
  | 'labels'
  | 'tf'
  | 'tfjs'
  | 'tflite'
  | 'website'
  | S3LinksKeySpecifier
)[];
export type S3LinksFieldPolicy = {
  labels?: FieldPolicy<any> | FieldReadFunction<any>;
  tf?: FieldPolicy<any> | FieldReadFunction<any>;
  tfjs?: FieldPolicy<any> | FieldReadFunction<any>;
  tflite?: FieldPolicy<any> | FieldReadFunction<any>;
  website?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatsKeySpecifier = (
  | 'accuracyByEpochs'
  | 'lossByEpochs'
  | 'testAccuracy'
  | 'testLoss'
  | 'valAccuracyByEpochs'
  | 'valLossByEpochs'
  | StatsKeySpecifier
)[];
export type StatsFieldPolicy = {
  accuracyByEpochs?: FieldPolicy<any> | FieldReadFunction<any>;
  lossByEpochs?: FieldPolicy<any> | FieldReadFunction<any>;
  testAccuracy?: FieldPolicy<any> | FieldReadFunction<any>;
  testLoss?: FieldPolicy<any> | FieldReadFunction<any>;
  valAccuracyByEpochs?: FieldPolicy<any> | FieldReadFunction<any>;
  valLossByEpochs?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SubscriptionKeySpecifier = ('subscribeToModelUpdates' | SubscriptionKeySpecifier)[];
export type SubscriptionFieldPolicy = {
  subscribeToModelUpdates?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TrainingParametersKeySpecifier = (
  | 'batchSize'
  | 'epochs'
  | 'learningRate'
  | TrainingParametersKeySpecifier
)[];
export type TrainingParametersFieldPolicy = {
  batchSize?: FieldPolicy<any> | FieldReadFunction<any>;
  epochs?: FieldPolicy<any> | FieldReadFunction<any>;
  learningRate?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TypedTypePolicies = TypePolicies & {
  ApiKey?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ApiKeyKeySpecifier | (() => undefined | ApiKeyKeySpecifier);
    fields?: ApiKeyFieldPolicy;
  };
  Label?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | LabelKeySpecifier | (() => undefined | LabelKeySpecifier);
    fields?: LabelFieldPolicy;
  };
  LabelWithPoses?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | LabelWithPosesKeySpecifier | (() => undefined | LabelWithPosesKeySpecifier);
    fields?: LabelWithPosesFieldPolicy;
  };
  Model?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ModelKeySpecifier | (() => undefined | ModelKeySpecifier);
    fields?: ModelFieldPolicy;
  };
  ModelListResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ModelListResultKeySpecifier
      | (() => undefined | ModelListResultKeySpecifier);
    fields?: ModelListResultFieldPolicy;
  };
  Mutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MutationKeySpecifier | (() => undefined | MutationKeySpecifier);
    fields?: MutationFieldPolicy;
  };
  Project?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ProjectKeySpecifier | (() => undefined | ProjectKeySpecifier);
    fields?: ProjectFieldPolicy;
  };
  ProjectListResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ProjectListResultKeySpecifier
      | (() => undefined | ProjectListResultKeySpecifier);
    fields?: ProjectListResultFieldPolicy;
  };
  Query?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier);
    fields?: QueryFieldPolicy;
  };
  S3Links?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | S3LinksKeySpecifier | (() => undefined | S3LinksKeySpecifier);
    fields?: S3LinksFieldPolicy;
  };
  Stats?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | StatsKeySpecifier | (() => undefined | StatsKeySpecifier);
    fields?: StatsFieldPolicy;
  };
  Subscription?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SubscriptionKeySpecifier | (() => undefined | SubscriptionKeySpecifier);
    fields?: SubscriptionFieldPolicy;
  };
  TrainingParameters?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TrainingParametersKeySpecifier
      | (() => undefined | TrainingParametersKeySpecifier);
    fields?: TrainingParametersFieldPolicy;
  };
};
