import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import LinearProgress from '@material-ui/core/LinearProgress';
import { QueryLazyOptions } from '@apollo/client';
import { Storage } from 'aws-amplify';

import { saveAs } from 'file-saver';

import parse from 'url-parse';
import { ButtonGroup } from '@material-ui/core';
import { getErrorMessage } from '../../utils/messages';
import ExportDialog from '../project/ExportDialog';
import {
  Model as ModelType,
  S3Links,
  TrainingStatus,
  useDeleteModelMutation,
  useOnModelChangeSubscription,
  useGetModelLazyQuery,
} from '../../API';
import ConfirmDialog from '../common/ConfirmDialog';
import ModelStats from './ModelStats';
import ModelGraphs from './ModelGraphs';

export type ModelProps = {
  model: ModelType;
  index: number;
  onDownload: (arg: QueryLazyOptions<{ pk: string; sk: string }>) => void;
  onDelete: (modelId: string) => void;
  onUpdate: (model: ModelType) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      paddingTop: theme.spacing(1),
      '& > *': {
        margin: theme.spacing(0, 1, 1, 0),
      },
    },
    loader: {
      margin: theme.spacing(2, 0),
    },
    chip: {
      margin: theme.spacing(0, 2),
    },
    table: {
      marginBottom: theme.spacing(1),
    },
    buttonGroupItemMiddle: {
      borderRightColor: 'transparent',
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      marginLeft: '-1px',
    },
    buttonGroupItemRight: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      marginLeft: '-1px',
    },
    lists: {
      display: 'flex',
    },
    list: {
      marginRight: theme.spacing(4),
    },
  })
);

const Model = ({
  index,
  model: defaultModel,
  onDownload,
  onDelete,
  onUpdate,
}: ModelProps): JSX.Element => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [model, setModel] = useState<ModelType>(defaultModel);
  const [deleteModel] = useDeleteModelMutation();

  const { status, pk, sk, createdAt, stats } = model;
  const s3Links = model.s3Links || {};
  const { website } = s3Links;

  const trainingParameters = model.trainingParameters || {};
  const { epochs, batchSize } = trainingParameters;

  const name = createdAt ? moment(createdAt).format('LLL') : pk;

  const { data } = useOnModelChangeSubscription({
    variables: { sk, pk },
  });

  const [websiteHtml, setWebsiteHtml] = useState('');

  const subscriptionData = data?.subscribeToModelUpdates;

  useEffect(() => {
    if (!subscriptionData) return;

    setModel({ ...model, ...subscriptionData } as ModelType);

    if (subscriptionData.status !== model.status) {
      model.status = subscriptionData.status;
      model.s3Links = subscriptionData.s3Links;
      model.stats = subscriptionData.stats;
      onUpdate(model);
      enqueueSnackbar(`Model ${name} was updated. Status: ${subscriptionData?.status}`, {
        variant: 'success',
      });
    }
    // eslint-disable-next-line
  }, [subscriptionData]);

  const handleDownload = useCallback(() => {
    onDownload({ variables: { sk, pk } });
    // eslint-disable-next-line
  }, [sk, pk]);

  const downloadFile = async (href: string) => {
    const { pathname } = parse(href);
    const fileName = pathname.substring(pathname.lastIndexOf('/') + 1);
    const pathStripped = pathname.substr(1);
    const result = (await Storage.get(pathStripped, {
      download: true,
      customPrefix: {
        public: '',
      },
    })) as any;
    saveAs(result.Body, fileName);
  };

  const getWebsiteHtml = async (url: string): Promise<string> =>
    window.fetch(url).then((response) => response.text());

  const handleError = (err: any) => {
    const { message, variant }: any = getErrorMessage(err);
    enqueueSnackbar(message, { variant });
  };

  const handleDelete = async () => {
    try {
      await deleteModel({ variables: { pk, sk } });
      onDelete(sk);
      enqueueSnackbar(`Model ${sk} was deleted`, { variant: 'success' });
    } catch (err) {
      handleError(err);
    }
  };

  const [getModel] = useGetModelLazyQuery({
    onCompleted: (data) => {
      if (data?.getModel) {
        setModel({ ...model, ...data?.getModel } as ModelType);
        onUpdate(data?.getModel);
      }
    },
  });

  return (
    <>
      {!!index && <Divider />}
      <article>
        <Typography component="h6" variant="h6" gutterBottom>
          {name}
          {!!status && (
            <>
              <Chip
                className={classes.chip}
                color={status === TrainingStatus.Ready ? 'secondary' : 'default'}
                label={status}
              />
              {status === TrainingStatus.Ready && (
                <ModelGraphs
                  renderTrigger={(open: () => void) => (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        if (!stats?.lossByEpochs) {
                          getModel({ variables: { sk, pk } });
                        }
                        open();
                      }}
                    >
                      Graphs
                    </Button>
                  )}
                  lossByEpochs={stats?.lossByEpochs}
                  valLossByEpochs={stats?.valLossByEpochs}
                  accuracyByEpochs={stats?.accuracyByEpochs}
                  valAccuracyByEpochs={stats?.valAccuracyByEpochs}
                />
              )}
            </>
          )}
        </Typography>
        <ModelStats
          epochs={epochs}
          batchSize={batchSize}
          testAccuracy={stats?.testAccuracy}
          testLoss={stats?.testLoss}
        />
        <div className={classes.actions}>
          {Object.entries(s3Links as S3Links)
            .filter(([name, href]) => name !== '__typename' && href)
            .map(([name, href]) => {
              if (href?.startsWith('s3://')) {
                return (
                  <Button
                    key={href}
                    color="primary"
                    onClick={async () => {
                      downloadFile(href!);
                    }}
                  >
                    {name}
                  </Button>
                );
              }
              return (
                <Button key={href} color="primary" href={href!} target="__blank">
                  {name}
                </Button>
              );
            })}
          {status === TrainingStatus.Pending && <LinearProgress className={classes.loader} />}
        </div>
        <ButtonGroup size="large" color="primary" aria-label="large outlined primary button group">
          <Button variant="outlined" color="primary" onClick={handleDownload}>
            Download
          </Button>
          <ExportDialog
            websiteHtml={websiteHtml}
            renderTrigger={(open: () => void) => (
              <Button
                className={classes.buttonGroupItemMiddle}
                variant="outlined"
                color="primary"
                onClick={async () => {
                  open();
                  setWebsiteHtml(await getWebsiteHtml(website!));
                }}
              >
                Export as HTML
              </Button>
            )}
          />
          <ConfirmDialog
            title="Permanently delete model?"
            message='To confirm deletion, click the "Delete" button.'
            onConfirm={handleDelete}
            actionLabel="Delete"
            renderTrigger={(open: () => void) => (
              <Button
                className={classes.buttonGroupItemRight}
                variant="outlined"
                color="primary"
                onClick={open}
              >
                Remove
              </Button>
            )}
          />
        </ButtonGroup>
      </article>
    </>
  );
};

export default Model;
